.chatbot-container {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.chatbot-form-container {
  margin-bottom: 20px;
}

.chatbot-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chatbot-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.chatbot-input:focus {
  outline: none;
  border-color: #007bff;
}

.chatbot-submit-button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.chatbot-submit-button:hover {
  background-color: #0056b3;
}

.chatbot-response {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 4px;
  border-left: 4px solid #007bff;
}
